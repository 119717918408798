import classNames from 'classnames';
import React, { ComponentProps, FC } from 'react';

export const SubmitButton: FC<ComponentProps<'input'>> = ({
  className,
  ...rest
}) => {
  return (
    <input
      className={classNames(
        'shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded col-start-1',
        className,
      )}
      type="submit"
      {...rest}
    />
  );
};
