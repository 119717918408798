import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useMutation } from 'urql';

import { RewardDetail_viewer_reward } from '@/blocks/reward-detail-block/__generated__/RewardDetail';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  TextareaField,
  useModalContext,
} from '@/components';
import { SubmitButton } from '@/components/form/SubmitButton';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import { PlayersSearch } from '../../components/PlayersSearch';
import { RewardPlayerAssignFormStaticQuery } from './__generated__/RewardPlayerAssignFormStaticQuery';

const query = graphql`
  query RewardPlayerAssignFormStaticQuery {
    sanityRewardPlayerAssignForm {
      title {
        ...LocaleString
      }
      players {
        ...LocaleString
      }
      note {
        ...LocaleString
      }
      assignButton {
        ...LocaleString
      }
      successMessage {
        ...LocaleString
      }
    }
  }
`;

const assignPlayerToRewardMutation = gql`
  mutation AssignPlayerToReward(
    $id: String!
    $comment: String!
    $playerIds: [String!]!
  ) {
    assignPlayers(id: $id, comment: $comment, playerIds: $playerIds)
  }
`;

type Props = {
  reward: RewardDetail_viewer_reward | null | undefined;
};

type FormValues = {
  comment: string;
  playerIds: string[];
};

const RewardPlayerAssignForm = ({ reward }: Props) => {
  const { t } = useTranslate();
  const staticData = useStaticQuery<RewardPlayerAssignFormStaticQuery>(query);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const params = useParams();

  const form = staticData.sanityRewardPlayerAssignForm;
  assert(form, 'missing form data');

  const [assignPlayerToRewardState, assignPlayerToReward] = useMutation(
    assignPlayerToRewardMutation,
  );

  const defaultValues: FormValues = {
    comment: '',
    playerIds: [],
  };

  const onSubmit = (values: FormValues) => {
    const variables = {
      id: params.rewardId,
      comment: values.comment,
      playerIds: values.playerIds,
    };

    return assignPlayerToReward(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(form.successMessage));
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={`${t(form.title)}: ${reward?.name}`}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex p-6">
        <Form
          className="w-full"
          defaultValues={defaultValues}
          onSubmit={onSubmit}
        >
          <div className="flex sm:flex-row flex-col space-x-6 pb-6">
            <div className="flex-1 space-y-4">
              <PlayersSearch
                name="playerIds"
                id="RewardPlayerAddForm__playerIds"
                title={t(form.players)}
              />
            </div>
            <div className="flex-1">
              <TextareaField
                name="comment"
                title={t(form.note)}
                id="RewardPlayerAddForm__comment"
              />
            </div>
          </div>
          <ErrorMessage message={errorMessage} />
          <div className="flex justify-end">
            <SubmitButton
              value={t(form.assignButton)}
              disabled={assignPlayerToRewardState.fetching}
            />
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default RewardPlayerAssignForm;
